












import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Subject, of, Observable, from } from 'rxjs';
import { delay, map, switchMap } from 'rxjs/operators';
import { DiveSite } from '../../model/DiveSite';
import SearchResults from './SearchResults.vue';
import axios from 'axios';

@Component({
  components: {
    SearchResults,
  },
})
export default class SearchDiveSite extends Vue {
  private searchString: string = '';
  private searchSubject: Subject<string> = new Subject();
  private results: DiveSite[] = [];

  @Watch('searchString')
  private search() {
    this.searchSubject.next(this.searchString);
  }

  private created() {
    this.searchSubject
      .pipe(
        switchMap((str) =>
          of(str).pipe(delay(500)),
        ),
        switchMap((str) => {
          if (str === '') {
            return of([]);
          } else {
            return this.fetchDiveSites(str);
          }
        }),
      )
      .subscribe((results) => {
        this.results = results;
      });
  }

  private fetchFromApi(uri: string): Observable<any> {
    return from(axios.get(`${process.env.VUE_APP_API_URL}/api/${uri}`));
  }

  private fetchDiveSites(str: string): Observable<DiveSite[]> {
    const lang = this.getLang();
    return this.fetchFromApi(`atlas/search?search=${str}&lang=${lang}`)
      .pipe(
        map((response) => response.data),
      );
  }

  private getLang(): string {
    return (window as any).dirlogbook.lang;
  }
}
