import 'es6-promise/auto';
import Vue from 'vue';
import App from './App.vue';
import SearchDiveSite from './components/SearchDiveSite/SearchDiveSite.vue';

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(SearchDiveSite),
}).$mount('#search-dive-site');
