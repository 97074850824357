















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SearchResults extends Vue {
  @Prop()
  private results?: Array<{ id: number; name: string; }>;

  private getLang(): string {
    return (window as any).dirlogbook.lang;
  }
}
